@import '../../../UI/global.module.scss';

.container {
	max-width: 314px;
	border-radius: 12px;
	overflow: hidden;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15), 0px 1px 13px 0px rgba(0, 0, 0, 0.05);
	cursor: pointer;

	.containerImg {
		width: 100%;
		height: 234px;
		display: flex;
    justify-content: flex-end;
    align-items: center;
		overflow: hidden;
	}

	.image {
		width: 100%;
	}

	.details {
		display: flex;
		flex-direction: column;
		padding: 16px;
		gap: 40px;
	}
	
	.text {
		display: flex;
		flex-direction: column;
		gap: 9px;
	}

	.title {
		min-height: 90px;
		font-size: 24px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: -0.5px;
	}
	
	.description {
		display: none;
		min-height: 196px;
		font-size: 20px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.5px;
	}

	.button {
		display: flex;
    justify-content: flex-end;
    align-items: center;
		gap: 16px;
	}

	.moreDetails {
		font-size: 20px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.5px;
	}

	.plus {
		display: flex;
    justify-content: center;
    align-items: center;
		background-color: #bfe1fc;
		border-radius: 4.8px;
		width: 48px;
		height: 48px;
	}

	&:hover {
		.containerImg {
			height: 109px;
		}

		.description {
			display: flex;
		}

		.button {
			display: none;
		}
	}
}
